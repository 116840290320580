/* eslint-disable */

import React, { useRef, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Configuration, OpenAIApi } from 'openai'
import Button from '@mui/material/Button'
import SendIcon from '@mui/icons-material/Send'
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Person4Icon from '@mui/icons-material/Person4';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import axios from 'axios'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import {
    askQuestion,
    setQuestion,
} from '../../redux/virtualTA/virtualTAActions'
import { fetchSavedCoursesMeta } from '../../redux'
import zIndex from '@mui/material/styles/zIndex'
import { makeStyles } from '@mui/styles'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

// import { MemoryVectorStore } from 'langchain/vectorstores/memory'
// import { OpenAIEmbeddings } from 'langchain/embeddings/openai'
// import { TextLoader } from 'langchain/document_loaders/fs/text'

const dispatchAskQuestion = (
    dispatch,
    userId,
    courseId,
    question,
    answer,
    videoLinks,
    videoTitles,
    videoDescriptions
) => {
    dispatch(
        askQuestion(
            userId,
            courseId,
            question,
            answer,
            videoLinks,
            videoTitles,
            videoDescriptions
        )
    )
}

const apiUrl =
    'https://generativelanguage.googleapis.com/v1beta2/models/chat-bison-001:generateMessage?key='
const apiKey = process.env.REACT_APP_PALM_API_KEY
const requestUrl = `${apiUrl}${apiKey}`

const { GoogleGenerativeAI } = require('@google/generative-ai')
const genAI = new GoogleGenerativeAI(process.env.REACT_APP_PALM_API_KEY)

const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
})

const openai = new OpenAIApi(configuration) 



function CourseDropdown({
    courses,
    selectedCourse,
    handleDropdownClick,
    handleCourseChange,
    dropdownPlaceholder,
}) {
    return (
        <div style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '10px', fontWeight: 'bold',color:'#3f51b5' }}>
                Course:
            </span>
            <select
                onClick={handleDropdownClick}
                onChange={handleCourseChange}
                value={selectedCourse || 'Please choose a course'}
                style={{
                    height: '40px',
                    width: '300px',
                    padding: '8px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    borderColor: 'black',
                    backgroundColor: 'white',
                    color: 'black',
                    fontSize: '16px',
                    fontFamily:'Italic'
                }}
            >
                <option value="" disabled hidden>
                    {dropdownPlaceholder}
                </option>
                <option value="Please choose a course">
                    Please choose a course
                </option>
                {courses.map((course) => (
                    <option key={course.id} value={course.id}>
                        {course.name}
                    </option>
                ))}
            </select>
        </div>
    )
}

function VirtualTA() {
    const [question, setQ] = useState('')
    const [ytquestion, setytQ] = useState('')
    const [error, setError] = useState('')
    const [conversationHistory, setConversationHistory] = useState([])
    const [selectedCourse, setSelectedCourse] = useState(null)
    const [showCourseDropdown, setShowCourseDropdown] = useState(false) // New state to control the visibility of the course dropdown
    const dispatch = useDispatch()
    const conversationRef = useRef(null)
    const userData = useSelector((state) => state.userData)
    const savedCourseData = useSelector((state) => state.savedCourseData)
    let botResponse = ''
    const userId = userData.user ? userData.user.id : null
    const defaultCourseOption = { id: userId, name: 'General' }
    const [queryInProgress, setQueryInProgress] = useState(false)
    const [initialMessagesShown, setInitialMessagesShown] = useState(false)
    const [dropdownPlaceholder, setDropdownPlaceholder] = useState(
        'Please choose a course'
    )
    const [virtualTAOpenTime, setVirtualTAOpenTime] = useState(false)
    const [showWelcomeMessage, setShowWelcomeMessage] = useState(false)
    const [showRelatedVideos, setShowRelatedVideos] = useState(false)
    const [videoLinks, setVideoLinks] = useState([])
    const [videoTitles, setVideoTitles] = useState([])
    const [videoDescriptions, setVideoDescriptions] = useState([])
    const [loadingVideos, setLoadingVideos] = useState(false)
    const [selectedCourseName, setSelectedCourseName] = useState(null)

    const model = genAI.getGenerativeModel({ model: 'gemini-pro' })

    const useStyles = makeStyles(()=>({
        dropdownSect:{
            borderRadius:'100px',
        },
        messageContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '10px',
        },
        messageContent: {
            backgroundColor: '#cad8e6',
            borderRadius: '10px',
            padding: '10px',
            margin: '10px',
            marginLeft: '20px',
            marginBottom: '5px',
            alignSelf: 'flex-start',
            maxWidth: '70%',
        },
        iconsContainer: {
            display: 'flex',
            marginLeft: '20px',
            alignSelf: 'flex-start',
            alignItems: 'center',
        },
        chatBotIcon: {
            color: 'blue', 
            marginRight: '5px', 
        },
        userIcon: {
            color: 'green', 
            marginRight: '5px', 
        },
        icon: {
            marginRight: '5px',
        },
    }));
    const classes = useStyles();
    useEffect(() => {
        window.scrollTo(0, 0)
        setVirtualTAOpenTime(true)
        setInitialMessagesShown(true)
        // Other code remains unchanged
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
        if (
            userData.userFetchCompleted &&
            userData.user &&
            userData.user.id !== undefined
        ) {
            dispatch(fetchSavedCoursesMeta())
            setSelectedCourse(userId)
        }

        // Show initial messages after the component is rendered
        if (virtualTAOpenTime) {
            const delay = setTimeout(() => {
                setInitialMessagesShown(true)
            }, 2000) // 2000 milliseconds delay

            return () => clearTimeout(delay)
        }
    }, [dispatch, userData.userFetchCompleted, userData.user])

    useEffect(() => {
        // Show the course dropdown after the initial messages are shown
        if (initialMessagesShown) {
            const delay = setTimeout(() => {
                setShowCourseDropdown(true)
            }, 2000) // 2000 milliseconds delay

            return () => clearTimeout(delay)
        }
    }, [initialMessagesShown])

    const handleDropdownClick = () => {
        setDropdownPlaceholder('') // Clear the placeholder when the dropdown is clicked
    }

    useEffect(() => {
        // This block will be executed when selectedCourse changes
        if (selectedCourse) {
            // Set the selected course name here
            const selectedCour = courses.find(
                (course) => course.id === selectedCourse
            )
            if (selectedCour) {
                setSelectedCourseName(selectedCour.name)
            }
        }
    }, [selectedCourse])

    useEffect(() => {
        const fetchData = async () => {
            try {
                setSelectedCourse(userId.toString())
            } catch (err) {
                console.error('Error fetching questions and answers:', err)
            }
        }
        // handleInformationBaseSearch()
        fetchData()
    }, [])

    useEffect(() => {
        // Scroll to the last question in the conversation
        // console.log(conversationHistory)
        if (conversationRef.current) {
            const lastQuestion = conversationRef.current.lastChild
            if (lastQuestion) {
                lastQuestion.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }, [conversationHistory])

    // const handleInformationBaseSearch = async () => {
    //     const loader = new TextLoader(
    //         'D:\Sushmit Dasgupta\GJUAF\edm-combined\edm\EDM-React\src\components\commons\ftp.txt'
    //     )
    //     const docs = await loader.load()

    //     // Load the docs into the vector store
    //     const vectorStore = await MemoryVectorStore.fromDocuments(
    //         docs,
    //         new OpenAIEmbeddings()
    //     )

    //     // Search for the most similar document
    //     const resultOne = await vectorStore.similaritySearch('Which connection does not remain open throughout the FTP session?', 1)

    //     console.log(resultOne)
    // }

    const handleShowRelatedVideos = async () => {
        setVideoLinks([])
        setVideoTitles([])
        setVideoDescriptions([])
        try {
            const youtubeResponse = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/youtube/search`,
                {
                    params: {
                        q: ytquestion,
                        part: 'snippet',
                        maxResults: 5,
                        type: 'video',
                    },
                }
            )
            const newVideoLinks = youtubeResponse.data.map(
                (item) => `https://www.youtube.com/watch?v=${item.videoId}`
            )
            const newVideoTitles = youtubeResponse.data.map(
                (item) => item.title
            )
            const newVideoDescriptions = youtubeResponse.data.map(
                (item) => item.description
            )
            console.log(question)
            console.log(newVideoTitles)

            // Check if there is an existing message with the same question
            const lastMessageIndex = conversationHistory.length - 1

            if (lastMessageIndex >= 0) {
                const lastMessage = conversationHistory[lastMessageIndex]

                const updatedMessage = {
                    ...lastMessage,
                    videoLinks: newVideoLinks,
                    videoTitles: newVideoTitles,
                    videoDescriptions: newVideoDescriptions,
                }

                setConversationHistory((prevHistory) => [
                    ...prevHistory.slice(0, lastMessageIndex),
                    updatedMessage,
                ])

                setShowRelatedVideos(true)
            } else {
                // Save video links in a new message if there are no messages
                const newMessage = {
                    question,
                    answer: <><CircularProgress size={20}></CircularProgress><LoadingDots /></>,
                    isCourseRelated: '',
                    loading: true,
                    videoLinks: newVideoLinks,
                    videoTitles: newVideoTitles,
                    videoDescriptions: newVideoDescriptions,
                }

                setConversationHistory((prevHistory) => [
                    ...prevHistory,
                    newMessage,
                ])
                setShowRelatedVideos(true)
            }
            setytQ('')
        } catch (error) {
            console.error('Error fetching YouTube videos:', error)
            setVideoLinks([])
            setVideoTitles([])
            setVideoDescriptions([])
        }
    }

    const handleLoadVideos = async () => {
        setLoadingVideos(true)
        try {
            await handleShowRelatedVideos()
        } finally {
            setLoadingVideos(false)
        }
    }

    const handleVideosLoaded = () => {
        setLoadingVideos(false)
        setVideoLinks([])
        setVideoTitles([])
        setVideoDescriptions([])
    }

    const handleQuestionChange = (event) => {
        setQ(event.target.value)
        dispatch(setQuestion(event.target.value))
    }

    const searchAnswerInSyllabus = async (question1) => {
        try {
            const syllabusdetails = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/course/${selectedCourse}`
            )
            const { syllabus } = syllabusdetails.data

            const prompt = `${syllabus}Give the answer to the following question strictly from this data. If the data is not found, output If the data is not found, output - "The requested information is not available".\n\n${question1}`
            const result = await model.generateContent(prompt)
            const response = result.response
            // answer = response.text()
            // const response = await openai.createCompletion({
            //     model: 'text-davinci-003',
            //     prompt,
            //     temperature: 0.6,
            //     max_tokens: 1000,
            // })

            return response.text()
        } catch (err) {
            console.error(err)
            throw new Error('Error searching for answer in the syllabus.')
        }
    }
    const LoadingDots = () => {
        const [dots, setDots] = useState('Loading');
    
        useEffect(() => {
            const intervalId = setInterval(() => {
                setDots((prevDots) => {
                    return prevDots.length >= 18 ? 'Loading' : prevDots + '.';
                });
            }, 500);
    
            return () => clearInterval(intervalId);
        }, []);
    
        return (
            <Typography variant="body1" style={{ color: 'white', fontWeight: 'bold', fontStyle: 'italic' }}>
                {dots}
            </Typography>
        );
    };
    const handleCourseChange = (event) => {
        const courseId = event.target.value
        const selectedCour = courses.find((course) => course.id === courseId)
        if (selectedCour) {
            setSelectedCourseName(selectedCour.name)
        }
        setSelectedCourse(courseId)
        setConversationHistory([])
        setInitialMessagesShown(false)
        setQuestion('')
        setShowWelcomeMessage(true)
    }
    function WelcomeMessage() {
        return (
            <div>
                <div
                    key="message1"
                    style={{
                        backgroundColor: '#cad8e6',
                        borderRadius: '10px',
                        padding: '10px',
                        margin: '10px',
                        marginLeft: '30px',
                        marginBottom: '15px',
                        alignSelf: 'flex-start',
                        maxWidth: '85%',
                    }}
                >
                    <p style={{ margin: '10px' }}>
                        Hi! I am your myTutor! You may ask any doubts related to any
                        concept as well as course details related queries. Happy
                        Learning!
                    </p>
                    <Person4Icon className={classes.chatBotIcon}/>
                </div>
            </div>
        )
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        setError('')
        setytQ(question)
        setQ('')
        setQueryInProgress(true)
        setLoadingVideos(false)
        setVideoLinks([])
        setVideoTitles([])
        setVideoDescriptions([])
        setShowRelatedVideos(false)

        try {
            const newMessage = {
                question,
                answer: <><CircularProgress size={20}></CircularProgress><LoadingDots /></>, // Placeholder for the answer
                isCourseRelated: '',
                loading: true, // Flag to indicate loading
            }

            setConversationHistory((prevHistory) => [
                ...prevHistory,
                newMessage,
            ])

            const classificationPrompt = `This is a classification prompt.\n\nA question is course related if it asks something related to the course details, such as exam venues, exam dates, or course syllabus or text books. A query is topic related if someone asks a question on a specific topic, such as "What is a relational database?" or "What is data abstraction?". If the question is course related, provide the answer as "course"; otherwise, provide the answer as 0. Classify whether the following question is course-related or a topic question:\n\n"${question}"\n\n---\nClassify category:`
            const result = await model.generateContent(classificationPrompt)
                const response = result.response
                // answer = response.text()
            // const { data: classificationData } = await openai.createCompletion({
            //     model: 'text-davinci-003',
            //     prompt: classificationPrompt,
            //     temperature: 0,
            //     max_tokens: 1,
            //     n: 1,
            //     stop: '---',
            // })

            // const requestBody = {
            //     prompt: { messages: [{ content: question }] },
            //     temperature: 0.1,
            //     candidate_count: 1,
            // }

            // fetch(requestUrl, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(requestBody),
            // })
            //     .then((response) => {
            //         if (!response.ok) {
            //             throw new Error('Network response was not ok')
            //         }
            //         return response.json()
            //     })
            //     .then((data) => {
            //         if (!data.candidates || data.candidates.length === 0) {
            //             throw new Error(
            //                 'Invalid response format or no candidates found'
            //             )
            //         }

            //         botResponse = data.candidates[0].content
            //         console.log('Bot response:', botResponse)
            //     })
            //     .catch((error1) => {
            //         // Handle any errors that occurred during the fetch or data processing
            //         console.error('Error:', error1.message)
            //     })
            // const isCourseRelated = classificationData.choices[0].text.trim()
            const isCourseRelated = response.text()
            console.log(isCourseRelated);

            let answer = ''
            let videoLinks = []
            let videoTitles = []
            let videoDescriptions = []

            if (isCourseRelated === 'course') {
                // Search answer strictly in syllabus
                answer = await searchAnswerInSyllabus(question)
            } else {
                const prompt1 = `${question}. Answer this question in context of ${selectedCourseName}. If this question is out of scope for the course ${selectedCourseName}, then return a message "This question is out of scope for the chosen course"`
                try {
                    console.log(selectedCourse)
                    const response = await fetch(
                        `https://turing.myacademic.org/search/?query=${encodeURIComponent(question)}&course_id=${selectedCourse}`,
                        {
                          method: "GET",
                          headers: {
                            "Content-Type": "application/json",
                          },
                        }
                      );
                
                      if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                      }
                
                      const data = await response.json();
                      answer = data.response;
                    console.log(data.response);
                  } catch (error) {
                    console.error("Error:", error.message);
                  } 

  
                // const result = await model.generateContent(prompt1)
                // const response = result.response
                // answer = response.text()
                console.log(answer)
                // const text = response.text();
                // const { data } = await openai.createCompletion({
                //     model: 'text-davinci-003',
                //     prompt: prompt1,
                //     temperature: 0.6,
                //     max_tokens: 400,
                // })
                // answer = data.choices[0].text.trim()
                // console.log(answer)

                // try {
                //     const youtubeResponse = await axios.get(
                //         `${process.env.REACT_APP_BASE_URL}/youtube/search`,
                //         {
                //             params: {
                //                 q: question,
                //                 part: 'snippet',
                //                 maxResults: 5,
                //                 type: 'video',
                //             },
                //         }
                //     )
                //     videoLinks = youtubeResponse.data.map(
                //         (item) =>
                //             `https://www.youtube.com/watch?v=${item.videoId}`
                //     )
                //     videoTitles = youtubeResponse.data.map((item) => item.title)
                //     videoDescriptions = youtubeResponse.data.map(
                //         (item) => item.description
                //     )
                // } catch (error2) {
                //     videoLinks = []
                //     videoTitles = []
                //     videoDescriptions = []
                // }
            }
            // if (isCourseRelated === '0') answer = `${answer}\n\n${botResponse}`

            setConversationHistory((prevHistory) =>
                prevHistory.map((message) =>
                    message === newMessage
                        ? {
                              ...message,
                              answer: (
                                  <div
                                      style={{
                                          whiteSpace: 'pre-wrap',
                                          maxWidth: '350px',
                                      }}
                                  >
                                      {answer}
                                  </div>
                              ), // Wrap in <pre> tag to preserve formatting
                              isCourseRelated,
                              loading: (answer.length > 60 || isCourseRelated === "course") ? false : true, // Turn off loading indicator

                          }
                        : message
                )
            )

            dispatchAskQuestion(
                dispatch,
                userId,
                selectedCourse,
                question,
                answer,
                [],
                [],
                []
                // videoLinks,
                // videoTitles,
                // videoDescriptions
            )

            setQuestion('')
            setQueryInProgress(false)
        } catch (err) {
            console.error(err)
            setError(
                'There was an error processing your request. Please try again.'
            )
            setQueryInProgress(false)
        }
    }

    const courses = savedCourseData.savedCoursesMeta.filter(
        (course) => course.name !== 'General'
    ) // Filter out the 'General' course
    // console.log('Test14')
    // console.log(showCourseDropdown)
    return (
        <div
            style={{
                
                paddingTop: '10px',
                backgroundColor: '#f7f7f7',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                zIndex: 1105,
            }}
        >
            <h3
                style={{
                    paddingBottom: '14px',
                    paddingLeft: '14px',
                    borderBottom: '0.7px solid grey',
                }}
            >
                {userData?.user?.role === 'Instructor' ? (
                    <span style={{color:"#3f51b5", marginLeft:"150px"}}>
                        <i>my</i>TA
                    </span>
                ) : (
                    <span style={{color:"#3f51b5"}}>
                        <i>my</i>Tutor
                    </span>
                )}
            </h3>

            <div
                style={{
                    height: 'calc(100vh - 90px)',
                    overflow: 'auto',
                }}
            >
            {console.log(initialMessagesShown)}
                {initialMessagesShown && (
                    <div>
                        
                        <div
                            key="message1"
                            style={{
                                backgroundColor: '#cad8e6',
                                borderRadius: '20px',
                                borderBottomLeftRadius: '0',
                                padding: '10px',
                                margin: '10px',
                                marginLeft: '20px',
                                marginBottom: '15px',
                                alignSelf: 'flex-start',
                                maxWidth: '70%',
                            }}
                        >
                            
                            <p style={{ margin: '10px' }}>
                                Hi! I am your virtual teaching assistant. You
                                may ask all your educational doubts here!
                            </p>
                            
                        </div>
                        <Person4Icon className={classes.chatBotIcon}/>
                        <div
                            key="message2"
                            style={{
                                backgroundColor: '#cad8e6',
                                borderRadius: '20px',
                                borderBottomLeftRadius: '0',
                                padding: '10px',
                                margin: '10px',
                                marginLeft: '20px',
                                alignSelf: 'flex-start',
                                maxWidth: '70%',
                            }}
                        >
                            <p style={{ margin: '10px' }}>
                                Please choose a course to proceed further...
                            </p>
                        </div>
                        <Person4Icon className={classes.chatBotIcon}/>
                    </div>
                )}

                {showWelcomeMessage && <WelcomeMessage />}

                {showCourseDropdown && (
                    <CourseDropdown
                        courses={courses}
                        selectedCourse={selectedCourse}
                        handleDropdownClick={handleDropdownClick}
                        handleCourseChange={handleCourseChange}
                        dropdownPlaceholder={dropdownPlaceholder}
                        className= {classes.dropdownSect}
                    />
                )}

                <div
                    ref={conversationRef}
                    style={{ overflow: 'auto', flex: '1' }}
                >
                    {conversationHistory.map((message, indx) => (
                        <div
                            key={indx}
                            style={{ marginBottom: '2px', userSelect: 'text'}}
                            
                        >
                            <div style={{ margin: '0' }} >
                                {/* <p style={{ fontWeight: 'bold', margin: '10px' }}>Question:</p> */}
                                <p
                                    style={{
                                        backgroundColor: '#e6e6e6',
                                        borderRadius: '20px',
                                        borderBottomRightRadius: '0',
                                        // border: '1px solid black',
                                        padding: '10px',
                                        margin: '10px',
                                        marginLeft: '90px',
                                        marginBottom: '15px',
                                        alignSelf: 'flex-start',
                                        maxWidth: '70%',
                                        // display: 'inline-block',
                                        // userSelect: 'text', // Make the text selectable
                                    }}
                                >
                                    {message.question}
                                    {/* <AccountCircleIcon className={classes.userIcon}/> */}
                                </p>
                               
                            </div>
                            
                            <div
                                
                                style={{
                                    paddingBottom: '5px',
                                    marginLeft: '20px',
                                    paddingTop: '1px',
                                    maxWidth: '80%',
                                    userSelect: 'text', // Make the text selectable
                                    zIndex: 1108,
                                }}
                            >
                                {/* <p style={{ fontWeight: 'bold', margin: '10px' }}>Answer:</p> */}
                                {message.isCourseRelated === '1' ? (
                                    <>
                                    <p
                                    
                                    style={{
                                        backgroundColor: '#cad8e6',
                                        padding: '10px',
                                        borderRadius: '20px',
                                        borderBottomLeftRadius: '0',
                                        // border: '1px solid black',
                                        margin: '10px',
                                        userSelect: 'text',
                                    }}
                                >
                                    {message.answer}
                                </p>
                                <Person4Icon className={classes.chatBotIcon}/>
                                    </>
                                    
                                ) : (
                                    <>
                                        <p
                                            style={{
                                                backgroundColor: 'rgb(202, 216, 230)',
                                                padding: '10px',
                                                borderRadius: '20px',
                                                borderBottomLeftRadius: '0',
                                                margin: '10px',
                                                userSelect: 'text', // Make the text selectable
                                            }}
                                        >
                                            {message.answer}
                                        </p>
                                        <Person4Icon className={classes.chatBotIcon}/>
                                        {/* {!showRelatedVideos &&
                                            !message.loading && (
                                                <Button
                                                    onClick={handleLoadVideos}
                                                    variant="outlined"
                                                    style={{
                                                        marginTop: '3px',
                                                        marginLeft: '8px',
                                                        marginRight: '8px',
                                                    }}
                                                >
                                                    {loadingVideos
                                                        ? 'Loading videos....'
                                                        : 'See related videos'}
                                                </Button>
                                            )} */}
                                        {message.videoLinks &&
                                            message.videoLinks.length > 0 && (
                                                <>
                                                    <p
                                                        style={{
                                                            fontWeight: 'bold',
                                                            margin: '10px',
                                                        }}
                                                    >
                                                        Suggested Videos:
                                                    </p>
                                                    <ul>
                                                        {message.videoLinks.map(
                                                            (link, idx) => (
                                                                <li
                                                                    key={`${message.question}-${idx}`}
                                                                >
                                                                    <OverlayTrigger
                                                                        placement="bottom"
                                                                        overlay={
                                                                            <Tooltip
                                                                                id={`tooltip-${idx}`}
                                                                            >
                                                                                {message.videoDescriptions &&
                                                                                message
                                                                                    .videoDescriptions[
                                                                                    idx
                                                                                ] &&
                                                                                message
                                                                                    .videoDescriptions[
                                                                                    idx
                                                                                ]
                                                                                    .length >
                                                                                    100
                                                                                    ? `${message.videoDescriptions[
                                                                                          idx
                                                                                      ].substring(
                                                                                          0,
                                                                                          100
                                                                                      )}...`
                                                                                    : (message.videoDescriptions &&
                                                                                          message
                                                                                              .videoDescriptions[
                                                                                              idx
                                                                                          ]) ||
                                                                                      ''}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <a
                                                                            href={
                                                                                link
                                                                            }
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            style={{
                                                                                color: 'blue',
                                                                                textDecoration:
                                                                                    'underline',
                                                                                cursor: 'pointer',
                                                                            }}
                                                                        >
                                                                            {message.videoTitles &&
                                                                            message
                                                                                .videoTitles[
                                                                                idx
                                                                            ]
                                                                                ? message
                                                                                      .videoTitles[
                                                                                      idx
                                                                                  ]
                                                                                : ''}
                                                                        </a>
                                                                    </OverlayTrigger>
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                    {/* {setLoadingVideos(false)} */}
                                                    {/* {handleVideosLoaded()} */}
                                                </>
                                            )}
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {showCourseDropdown && !initialMessagesShown && (
                <form
                    onSubmit={handleSubmit}
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '14px',
                        borderTop: '2px solid #BCBCBC ',
                    }}
                >
                    <input
                        type="text"
                        id="question"
                        name="question"
                        placeholder="Write your question..."
                        value={question}
                        onChange={handleQuestionChange}
                        style={{
                            height: '50px',
                            width: '100%',
                            padding: '12px 20px',
                            margin: '0',
                            display: 'inline-block',
                            border: '1px solid #BCBCBC',
                            borderRadius: '50px',
                            boxSizing: 'border-box',
                            outline: 'none',
                            backgroundColor: 'white',

                        }}
                    />
                    {!queryInProgress && (
                        <Button
                            type="submit"
                            variant="contained"
                            endIcon={
                                <SendIcon
                                    style={{
                                        color: question ? 'grey' : 'lightgrey',
                                    }}
                                />
                            }
                            aria-label="send"
                            style={{
                                backgroundColor: '#fff',
                                marginTop: '3px',
                                marginLeft: '8px',
                            }}
                            disabled={!question}
                        /> 
                    )}
                </form>
            )}

            {error && (
                <div
                    style={{
                        backgroundColor: 'white',
                        padding: '5px',
                        border: '1px solid black',
                    }}
                >
                    <p style={{ backgroundColor: 'white' }}>{error}</p>
                </div>
            )}
        </div>
    )
}

export default VirtualTA