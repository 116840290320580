/* eslint-disable */

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { TextField, Typography, Button, Box } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import EditOffIcon from '@mui/icons-material/EditOff'
import DeleteIcon from '@mui/icons-material/Delete'
import axios from 'axios'
import AWS from 'aws-sdk'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material'
import { setErrorForKey, updateCourseFields } from '../../../../redux'
import { addAnchorTagsForLinksInText } from '../../../../utils'

const S3_BUCKET_NAME = 'myacademic-syllabusfiles'
const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: 'us-east-1',
})

export default function CourseResources({ courseId }) {
    const dispatch = useDispatch()
    const errorData = useSelector((state) => state.errorData)
    const userData = useSelector((state) => state.userData)
    const currentCourseData = useSelector((state) => state.currentCourseData)
    const savedCourseData = useSelector((state) => state.savedCourseData)
    const sid = userData && userData.user && userData.user.id;
    const isInstructorOfCourse = (sid === currentCourseData.createdBy)
    const [fileList, setFileList] = useState([])
    const [selectedFile, setSelectedFile] = useState(null)
    const [isLinkModalOpen, setLinkModalOpen] = useState(false)
    const [linkInput, setLinkInput] = useState('')
    const [linkDescription, setLinkDescription] = useState('')
    const [courseLinks, setCourseLinks] = useState([])
    const [enableResourcesEdit, setEnableResourcesEdit] = useState(false)
    const [searchedResources, setSearchedResources] = useState('')
    const [deleteMode, setDeleteMode] = useState(false)
    const [selectedItems, setSelectedItems] = useState([])
    const [linkDelete, setLinkDelete] = useState([])
    const [uploading, setUploading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)
    const [role, setRole] = useState(null)
    

    useEffect(() => {
        async function fetchData() {
            try {
                const coursedetails = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/course/${courseId}`
                )
                const { resources } = coursedetails.data
                const { links } = coursedetails.data
                setCourseLinks(links)
                setSearchedResources(resources)
            } catch (err) {
                console.error(err)
                throw new Error('Error searching for resources text.')
            }
        }

        fetchData()
    }, [courseId]) // Add courseId as a dependency to re-fetch when courseId changes

    useEffect(() => {
        window.scrollTo(0, 0)
        if (
            userData.userFetchCompleted &&
            userData.user &&
            userData.user.id !== undefined
        ) {
            setRole(userData.user.role)
            if (userData.user.firstName === 'Admin') {
                setRole('Admin')
            }
        }
    }, [dispatch, userData.userFetchCompleted, userData.user])

    const handleEditButtonClick = () => {
        if (!enableResourcesEdit) {
            // Save changes if currently in edit mode
            dispatch(
                updateCourseFields(courseId, {
                    resources: searchedResources,
                })
            )
        }
        setEnableResourcesEdit(!enableResourcesEdit)
    }

    useEffect(() => {
        async function fetchData() {
            try {
                // Load files from S3 folder for the course ID
                const files = await listS3Objects(courseId)
                setFileList(files)
            } catch (err) {
                console.error(err)
                throw new Error('Files not found.')
            }
        }

        fetchData()
    }, [courseId])

    const listS3Objects = async (prefix) => {
        try {
            const params = {
                Bucket: S3_BUCKET_NAME,
                Prefix: prefix,
            }

            const data = await s3.listObjectsV2(params).promise()
            const files = data.Contents.map((obj) => obj.Key)
            const filteredFiles = files.filter(file => file !== `${courseId}-syllabus.pdf`);
            return filteredFiles
        } catch (error) {
            console.error('Error listing S3 objects: ', error)
            return []
        }
    }

    const handleFileUpload = async () => {
        if (!selectedFile) {
            return
        }
        const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("course_id", courseId);
        await axios.post(
        "https://turing.myacademic.org/uploadpdf",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },          
        }
      );


        try {
            const fileName = `${courseId}/${selectedFile.name}`
            const params = {
                Bucket: S3_BUCKET_NAME,
                Key: fileName,
                Body: selectedFile,
            }

            await s3.upload(params).promise()
            alert('File successfully uploaded!')
            // Refresh the file list
            const files = await listS3Objects(courseId);
            const filteredFiles = files.filter(file => file !== `${courseId}-syllabus.pdf`);
            setFileList(filteredFiles);
            setSelectedFile(null);
            
        } catch (error) {
            console.error('Error uploading file: ', error)
            alert('File not uploaded!')
        }
    }

    const handleFileDownload = (fileKey) => {
        const url = s3.getSignedUrl('getObject', {
            Bucket: S3_BUCKET_NAME,
            Key: fileKey,
        })

        // You can now open the URL in a new tab or use it as needed
        window.open(url, '_blank')
    }

    const handleDeleteButtonClick = () => {
        setDeleteMode(!deleteMode)
        setSelectedItems([])
    }

    const handleDeleteItem = (item, type) => {
        let updatedItems

        if (type === 'file') {
            updatedItems = fileList.filter((file) => file !== item)
        } else if (type === 'link') {
            updatedItems = courseLinks.filter((link) => link !== item)
        }

        setSelectedItems([item]) // Use an array with a single item

        if (deleteMode) {
            // Delete items permanently and reload resources and links
            handleDeleteItems(updatedItems, type)
        }
    }

    const handleDeleteItems = async (items, type) => {
        try {
            // Implement deletion logic based on the type (file or link)
            if (type === 'file') {
                // Delete files from S3
                await Promise.all(
                    items.map(async (file) => {
                        await s3
                            .deleteObject({
                                Bucket: S3_BUCKET_NAME,
                                Key: file,
                            })
                            .promise()
                    })
                )
            } else if (type === 'link') {
                console.log(linkDelete)
                console.log(linkDelete)
                const updatedLinks = courseLinks.filter(
                    (link) => link.url !== linkDelete.url
                )

                // Update the state with the new set of links
                setCourseLinks(updatedLinks)
                setLinkDelete([])
                console.log('Link deleted successfully!')
                console.log(courseLinks)
                console.log(updatedLinks)

                // Update the database (dispatch an action to update the database)
                dispatch(
                    updateCourseFields(courseId, {
                        links: updatedLinks,
                    })
                )
            }

            // After deletion, refresh the file list and course links
            const updatedFiles = await listS3Objects(courseId)
            setFileList(updatedFiles)

            if (type === 'link') {
                // Refetch course details to get updated links
                const coursedetails = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/course/${courseId}`
                )
                const { links } = coursedetails.data
                setCourseLinks(links)
            }
        } catch (error) {
            console.error('Error deleting items: ', error)
        } finally {
            setDeleteMode(false)
            setSelectedItems([])
        }
    }

    useEffect(() => {
        // This effect will run whenever linkDelete changes
        if (linkDelete) {
            // Do something with linkDelete, e.g., dispatch an action or perform other operations
            console.log('Link to delete:', linkDelete)
        }
    }, [linkDelete])

    return (
        <div
            style={{
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'column',
                minHeight: '70vh',
            }}
        >
            <div>
                <h4>Resources:</h4>
                <ul>
                    {fileList.map((file) => (
                        <li key={file}>
                            <p>
                                <a
                                    href="#"
                                    onClick={() => handleFileDownload(file)}
                                >
                                    {file.split('/').pop()}
                                </a>
                                {deleteMode && (
                                    <span
                                        onClick={() =>
                                            handleDeleteItem(file, 'file')
                                        }
                                        style={{
                                            cursor: 'pointer',
                                            marginLeft: 'auto',
                                            marginRight: '10px',
                                        }}
                                    ></span>
                                )}
                            </p>
                        </li>
                    ))}
                </ul>
                <br></br>
                {uploading && (
                    <div>
                        <p>File is being uploaded...</p>
                        <progress value={uploadProgress} max={100} />
                    </div>
                )}
                {courseLinks.length > 0 && <h4>Links:</h4>}
                <ul>
                    {courseLinks.map((link, index) => (
                        <li key={`link-${index}`}>
                            <p
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {link.description}: {'  '}
                                <a
                                    href={link.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ marginRight: '10px' }}
                                >
                                    {link.url}
                                </a>
                                {deleteMode && role === 'Instructor' && (
                                    <span
                                        onClick={() => {
                                            handleDeleteItem(link, 'link')
                                            setLinkDelete(link)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            marginLeft: 'auto',
                                            marginRight: '10px',
                                        }}
                                    >
                                        <DeleteIcon />
                                    </span>
                                )}
                            </p>
                        </li>
                    ))}
                </ul>
            </div>
            {role === 'Instructor' && isInstructorOfCourse && (
                <Box
                    display="flex"
                    alignItems="center"
                    style={{ marginTop: 'auto' }}
                >
                    <input
                        type="file"
                        accept=".pdf,.doc,.docx,.pptx,.xlsx"
                        style={{ display: 'none' }}
                        id="file-input"
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                    />
                    <label htmlFor="file-input">
                        <Button
                            variant="contained"
                            color="primary"
                            component="span"
                        >
                            Choose File
                        </Button>
                    </label>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setLinkModalOpen(true)}
                        style={{ marginLeft: '10px', marginBottom: '6px' }}
                    >
                        Add Link
                    </Button>
                    <Dialog
                        open={isLinkModalOpen}
                        onClose={() => setLinkModalOpen(false)}
                    >
                        <DialogTitle>Add a Link</DialogTitle>
                        <DialogContent>
                            <TextField
                                label="Link Description"
                                variant="outlined"
                                fullWidth
                                value={linkDescription}
                                onChange={(e) =>
                                    setLinkDescription(e.target.value)
                                }
                            />
                            <TextField
                                label="Link URL"
                                variant="outlined"
                                fullWidth
                                value={linkInput}
                                style={{ marginTop: '10px' }}
                                onChange={(e) => setLinkInput(e.target.value)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color="primary"
                                onClick={async () => {
                                    let absoluteLink = linkInput
                                    if (
                                        !absoluteLink.startsWith('http://') &&
                                        !absoluteLink.startsWith('https://')
                                    ) {
                                        absoluteLink = `http://${absoluteLink}`
                                    }

                                    const newLink = {
                                        url: absoluteLink,
                                        description: linkDescription,
                                    }
                                    setCourseLinks([...courseLinks, newLink])
                                    dispatch(
                                        updateCourseFields(courseId, {
                                            links: [...courseLinks, newLink],
                                        })
                                    )
                                    setLinkModalOpen(false)
                                }}
                            >
                                Save
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => {
                                    setLinkModalOpen(false)
                                    setLinkInput('') // Clear the input
                                }}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {selectedFile && (
                        <Typography
                            variant="body1"
                            style={{ marginLeft: '10px', marginRight: 'auto' }}
                        >
                            {selectedFile.name}
                        </Typography>
                    )}
                    {courseLinks.length > 0 && (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleDeleteButtonClick}
                            style={{ marginLeft: '10px', marginBottom: '6px' }}
                        >
                            {deleteMode ? 'Cancel Delete' : 'Delete Links'}
                        </Button>
                    )}

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFileUpload}
                        disabled={!selectedFile}
                        style={{ marginLeft: 'auto' }}
                    >
                        Upload File
                    </Button>
                </Box>
            )}
            <br></br>
            <h4>Notes:</h4>
            {enableResourcesEdit && isInstructorOfCourse? (
                <TextField
                    error={!!errorData.resources}
                    fullWidth
                    // helperText={`${searchedResources.length}/512 characters`}
                    id="description-edit"
                    inputProps={{
                        style: {
                            padding: 0,
                            minHeight: '23.5%',
                            border: '1px solid black',
                            borderRadius: '3px',
                        },
                    }}
                    multiline
                    onBlur={handleEditButtonClick}
                    onChange={(e) => setSearchedResources(e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                    rows={4}
                    value={searchedResources}
                    variant="outlined"
                />
            ) : (
                <Typography
                    id="description"
                    onClick={(e) => e.stopPropagation()}
                    style={{
                        whiteSpace: 'pre-line',
                        border: '1px solid black',
                        borderRadius: '3px',
                    }}
                    sx={{
                        flexGrow: 20,
                        display: '-webkit-box',
                        overflow: 'scroll',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 4,
                        background: 'rgb(247, 247, 247)',
                        minHeight: '7.8em',
                        padding: '25px 12px 8px',
                        border: errorData.description
                            ? '1px red solid'
                            : 'none',
                    }}
                    variant="body1"
                >
                    {addAnchorTagsForLinksInText(searchedResources)}
                    {/* {searchedResources} */}
                </Typography>
            )}
            {role === 'Instructor' && isInstructorOfCourse && (
                <button
                    className="btn btn-primary m-2"
                    onClick={handleEditButtonClick}
                    style={{ width: 'fit-content' }}
                    type="button"
                >
                    {enableResourcesEdit ? (
                        <>
                            <EditOffIcon fontSize="small" />{' '}
                        </>
                    ) : (
                        <>
                            <EditIcon
                                style={{ marginTop: '5px' }}
                                fontSize="small"
                            />{' '}
                            Edit
                        </>
                    )}
                </button>
            )}
        </div>
    )
}

CourseResources.propTypes = {
    courseId: PropTypes.string.isRequired,
}