/*eslint-disable*/
import React, { useEffect, useState} from "react";
import {useSelector} from 'react-redux';
import Question from "./Question";
import styles from './Quiz.module.css'
import QuestionPalette from "./QuestionPalette";
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import Timer from './Timer';
import Result from "./Result";
import Modal from '@mui/material/Modal';
import { Button, LinearProgress } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import jaroWinkler from 'jaro-winkler';
import { useHistory } from "react-router-dom";
function QuizNew () {
    const [isOpenSubmitQuizModal, setIsOpenSubmitQuizModal] = useState(false);
    const [isOpenTimeoutModal, setIsOpenTimeoutModal] = useState(false);
    const [attempted, setAttempted] = useState(0);
    const [quiz, setQuiz] = useState(null);
    const [score, setScore] = useState(0);
    const [phase, setPhase] = useState(0);
    const [questionIndex, setQuestionIndex] = useState(0);
    const [results, setResults] = useState([]);
    const [progress, setProgress] = useState(100);
    const userData = useSelector((state) => state.userData)
    const history = useHistory();

    const handleSubmitQuizModalClose = () => {
        setIsOpenSubmitQuizModal(false);
    }

    const handleTimeoutModalClose = () => {
        setIsOpenTimeoutModal(false);
    }
    const { quiz_id: quizId } = useParams()
    
    const shuffle = (array) => {
        let currentIndex = array.length;
        while (currentIndex != 0) {
            let randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
        }
        return array
    }


    useEffect(() => {
        const fetchQuizData = async () => {
            
            const checkUserRole = () => {
                return userData && userData.user && userData.user.role !== null;
            };

            
            const waitForRole = () => {
                return new Promise((resolve) => {
                    const interval = setInterval(() => {
                        if (checkUserRole()) {
                            clearInterval(interval);
                            resolve();
                        }
                    }, 100); 
                });
            };

            await waitForRole(); 

            const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/quiz/${quizId}`);
            const quizObj = res.data;

            if (!quizObj.isPublished && userData.user.role !== 'Instructor') {
                alert("Quiz does not exist");
                history.push(`/course/home/${quizObj.courseId}`);
                return; 
            }

            setQuiz({
                title: quizObj.data.quizTitle,
                synopsis: quizObj.data.quizSynopsis,
                timeLimit: quizObj.timeLimit,
                questions: quizObj.data.questions.map((question, i) => ({
                    id: i,
                    type: (question.answerSelectionType === "single") ? "TF" : 
                          (question.answerSelectionType === "multiple") ? "MCQ" : "FITB",
                    statement: question.question,
                    questionImages: [],
                    options: shuffle(question.answers.map((answer) => ({
                        ...answer,
                        optionImages: []
                    }))),
                    correctAnswers: question.correctAnswer,
                    explanation: question.explanation || '',
                    points: question.point
                }))
            });

            console.log(JSON.stringify(userData));
        };

        fetchQuizData();
    }, [quizId, userData]);
    
    useEffect(()=>{
        setResults(
            quiz?.questions.map(question => (
                {
                    id: question.id,
                    selectedAnswers: question.type === "FITB" ? (
                        question.correctAnswers.map((correctAnswer) => ({
                            id: correctAnswer.id,
                            selectedAnswer: ''
                        }))
                    ) : (
                        []
                    ),
                    isCorrect: false,
                    isAttempted: false,
                    score: 0
                }
            ))
        )
    }, [quiz])

    const evaluateQuiz = () => {
        const checkIsCorrect = (type, correctAnswers, selectedAnswers, points) => {
            var isCorrect = true;
            var score = 0;

            if (type === 'MCQ'  || type === 'TF') {
                var noOfCorrectSelections = 0;
                var incorrectOptionSelected = false;
                selectedAnswers.forEach((selectedAnswer) => {
                    const selectedCorrectAnswersLength = (correctAnswers.filter(correctAnswer => correctAnswer.id === selectedAnswer.id)).length;
                    if (selectedCorrectAnswersLength === 0) {
                        incorrectOptionSelected = true;
                        isCorrect = false;
                    } else {
                        noOfCorrectSelections = noOfCorrectSelections + 1;
                    }
                })

                score = incorrectOptionSelected ? 0 : ((noOfCorrectSelections * 1.0) / correctAnswers.length) * parseFloat(points);
            } else if (type === 'FITB') {
                var noOfCorrectBlanks = 0
                selectedAnswers.forEach((selectedAnswer) => {
                    const correctAnswer = (correctAnswers.filter(correctAnswer => correctAnswer.id === selectedAnswer.id))[0]
                    var isNumeric = true;
                    correctAnswer.correctAnswers.forEach((correctAnswerStatement) => {
                        if (!parseFloat(correctAnswerStatement)) {
                            isNumeric = false;
                        }
                    })
                    const isBlankCorrect = isNumeric ? (
                        (correctAnswer.correctAnswers.filter(correctAnswerStatement => ((Math.abs(parseFloat(correctAnswerStatement) - parseFloat(selectedAnswer.selectedAnswer))) < 0.01))).length !== 0
                    ) : (
                        (correctAnswer.correctAnswers.filter(correctAnswerStatement => (jaroWinkler(correctAnswerStatement.toLowerCase(), (selectedAnswer.selectedAnswer).toLowerCase()) > 0.97))).length !== 0
                    )
                    isCorrect = (isCorrect && isBlankCorrect)
                    noOfCorrectBlanks += isBlankCorrect
                })
                score = ((noOfCorrectBlanks * 1.0) / correctAnswers.length) * parseFloat(points);
            }
            setScore(prevScore => (parseFloat(prevScore) + (score)).toFixed(2))
            
            return {isCorrect: isCorrect, score: score.toFixed(2)};
        } 
        
        setResults((prevResults) => (
                prevResults.map((prevResult, i) => ({
                        ...prevResult,
                        ...checkIsCorrect(quiz?.questions[i].type, quiz?.questions[i].correctAnswers, prevResult.selectedAnswers, quiz?.questions[i].points)
                    }
                )
            )
        ))

        
    }

    const handleOnTakeQuizClick = () => {
        setPhase(1);
    }

    const handleOnQuizEnd = () => {
        setPhase(2);
        evaluateQuiz();
    }

    return (
        <div className={`${styles['quiz-container']}`}>
            <div className={`${styles['quiz']}`}>
                <h1 className={`${styles['quiz-title']}`}>{quiz?.title}{phase === 2 && ": Results"}</h1>
                <div className={`${styles['quiz-timer']}`}>
                    <TimerOutlinedIcon style={{color: "#0055A2"}} />
                    {
                        phase === 0 ?
                        <h4 className={`${styles['quiz-timer']}`}>{quiz?.timeLimit}</h4> :
                        <Timer 
                            timeLimit={quiz?.timeLimit} 
                            isRunning={phase === 1} 
                            handleOnQuizEnd={()=>{
                                setIsOpenTimeoutModal(true);
                                handleSubmitQuizModalClose();
                            }}
                            setProgress={setProgress} 
                        />
                    }
                </div>
                <LinearProgress className={styles['progress']} variant="determinate" value={progress} color={progress > 20 ? 'primary' : progress > 10 ? 'warning' : 'error'} />
                
                <div className={`${styles['quiz-sidebar-container']}`}>
                    {
                        phase === 0 ? (
                            <>
                                <h4 className={`${styles['h4']} ${styles['row-center']}`}>{quiz?.questions.reduce((a, question) => (a + parseFloat(question.points)), 0) + ' Points'}</h4>
                                <div className={`${styles["quiz-button"]}`} type="button" onClick={handleOnTakeQuizClick}>Take Quiz</div>
                            </>
                        ) : (
                            <>
                                {
                                    phase === 1 ? (
                                        
                                            <div className={`${styles["quiz-button"]}`} onClick={()=>{setIsOpenSubmitQuizModal(true)}}>
                                                Submit Quiz
                                            </div>
                                    ) : (
                                        <h3 className={styles['score']}>
                                            Score: {score}/{quiz?.questions.reduce((accumulator, question)=>(accumulator + parseInt(question.points)), 0)}
                                        </h3>
                                    )
                                }  
                                <QuestionPalette 
                                    questionIndex={questionIndex}
                                    setQuestionIndex={setQuestionIndex}
                                    results={results}
                                    isRunning={phase===1}
                                />
                            </>
                        )
                    }
                </div>
                <div className={`${styles['quiz-main-container']}`}>
                    {
                        phase === 0 ? (
                            <>
                                <h4 className={`${styles['h4']} ${styles['quiz-info']}`}>{quiz?.questions.length + ' Questions'}</h4>
                                <>{quiz?.synopsis}</>
                            </>
                        ) : (
                            phase === 1 ? (
                                <Question 
                                    question={quiz?.questions[questionIndex]} 
                                    questionsCount={quiz?.questions.length} 
                                    questionIndex={questionIndex} 
                                    setQuestionIndex={setQuestionIndex} 
                                    results={results}
                                    setResults={setResults}
                                />
                            ) : (
                                <Result 
                                    question={quiz?.questions[questionIndex]} 
                                    questionsCount={quiz?.questions.length} 
                                    questionIndex={questionIndex} 
                                    setQuestionIndex={setQuestionIndex} 
                                    results={results}
                                    setResults={setResults}
                                />
                            )
                        )
                    }
                </div>
                <Modal
                    open={isOpenSubmitQuizModal}
                    onClose={handleSubmitQuizModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className={`${styles['modal-container']}`}>
                        <h2>Are you sure you want to submit?</h2>
                        <p>You have <b style={{color: "#1976d2"}}>{quiz?.questions?.length - (results?.filter(result => result.isAttempted === true))?.length} questions</b> yet to be attempted.</p>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                gap: '1em',
                                fontSize: "0.5rem"
                            }}
                        >
                            <Button
                                onClick={() => {
                                    handleSubmitQuizModalClose();
                                }}
                                variant="outlined"
                                disableElevation
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    handleSubmitQuizModalClose();
                                    handleOnQuizEnd();
                                }}
                                variant="contained"
                                disableElevation
                            >
                                Yes
                            </Button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    open={isOpenTimeoutModal}
                    onClose={handleTimeoutModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className={`${styles['modal-container']}`}>
                        <h2>Timeout</h2>
                        <p>Looks like your time is over!</p>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                gap: '1em',
                                fontSize: "0.5rem"
                            }}
                        >
                            <Button
                                onClick={() => {
                                    handleTimeoutModalClose();
                                    handleOnQuizEnd();
                                }}
                                variant="contained"
                                disableElevation
                            >
                                Okay
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
	)
    // return <></>
}

export default QuizNew;